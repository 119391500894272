<template>
	<div>
		<van-nav-bar
			title="忘記密碼"
			left-arrow
			class="qjc-nav-bar"
			fixed
			@click-left="$router.back()"
		/>
		
		<div class="forget qjc-texta-l qjc-input-group">
			<div v-if="step == 1">
				<h2 class="title font-44 qjc-c-dark">請輸入手機號</h2>
				<van-field
					placeholder="手機號"
					v-model="account"
				/>
				<van-button @click="getCode" round class="get-code qjc-ftw-b qjc-c-primary qjc-borderc-primary qjc-fts-32">獲取驗證碼</van-button>
			</div>
			<div v-else-if="step == 2">
				<h2 class="title qjc-fts-36 qjc-c-dark">我們已向 “{{ account }}” 發送了一個4位驗證碼</h2>
				
				<!-- 验证码 -->
				<van-password-input
				  :value="code"
				  :mask="false"
				  :length="4"
				  gutter="0.4rem"
				  :focused="showKeyboard"
				  @focus="showKeyboard = true"
				/>
				
				<div class="tip qjc-clearfix">
					<div class="left qjc-fl">
						<span v-if="countDown">
							<van-count-down
								ref="countDown"
								class="qjc-inline"
								:time="2*60*1000"
								format="ss"
								@finish="finished"
							/>s後重新獲取驗證碼
						</span>
						<span v-else @click="getCode" class="qjc-c-primary qjc-ftw-b">重新獲取驗證碼</span>
					</div>
					<div @click="step = 1" class="right qjc-fr qjc-c-primary qjc-ftw-b">
						重新編輯手機號
					</div>
				</div>
				 
				<!-- 数字键盘 -->
				<van-number-keyboard
				  :show="showKeyboard"
				  close-button-text="完成"
				  @input="onInput"
				  @delete="onDelete"
				  @blur="showKeyboard = false"
				/>
			</div>
			<div v-else-if="step == 3">
				<h2 class="title font-44 qjc-c-dark">設置新密碼</h2>
				<van-field
					placeholder="請輸入新密碼"
					v-model="pwd"
					type="password"
				/>
				<van-field
					placeholder="確認新密碼"
					v-model="pwd2"
					type="password"
				/>
				<van-button @click="reset" class="reset qjc-borderc-primary qjc-wid-100 qjc-c-primary qjc-fts-28">提交</van-button>
			</div>
		</div>
		
		<!-- 底部提示 -->
		<footer class="qjc-fixed qjc-wid-100">本產品目前僅適用於20-90周歲人群</footer>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, Button, PasswordInput, NumberKeyboard, CountDown, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(Button)
	   .use(PasswordInput)
	   .use(NumberKeyboard)
	   .use(CountDown)
	   .use(Toast);
	   
	import { isPhone, isHKPhone, isEmail, pwdReg } from "@/js/utils.js"
	import { accountExist, getVerifyCode, verifyCode, resetPwd } from "@/js/axios.js"
	
	export default{
		name: 'forgetPwd',
		data (){
			return {
				account: '',//手机号/邮箱
				step: 1,//当前所在步骤 默认1
				code: '',//验证码
				showKeyboard: false,//是否显示数字键盘 默认false
				countDown: true,//是否正在倒计时 默认true
				pwd: '',//新密碼
				pwd2: ''//確認新密碼
			}
		},
		watch: {
			//验证码长度为4时调接口
			code (){
				if(this.code.length >=4){
					verifyCode({
						account: this.account,
						code: this.code,
						// type: (isPhone(this.account) || isHKPhone(this.account))?2:(isEmail(this.account)?91:''),//1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码,
						type: 2
					}).then(res => {
						if(res.status == 1){
							this.step = 3;
						}else{
							Toast.fail(res.msg);
						}
					}) 
				}
			}
		},
		methods: {
			onInput (key){
				this.code = (this.code + key).slice(0,4);
			},
			onDelete (){
				this.code = this.code.slice(0, this.code.length - 1);
			},
			finished (){
				this.$refs.countDown.reset();
				this.countDown = false;
			},
			
			//获取验证码
			getCode (){
				if(this.account.trim() == ''){
					Toast.fail('手機號不能為空');
				}else{
					if(isPhone(this.account) || isHKPhone(this.account)){
						accountExist({
							account: this.account
						}).then(res => {
							if(res.status == 1){
								let data = {
									account: this.account,
									// type: (isPhone(this.account) || isHKPhone(this.account))?2:(isEmail(this.account)?91:''),//1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码,
									type: 2,
									loa: isHKPhone(this.account)?'00852':'86'
								}
								console.log(data);
								getVerifyCode(data).then(res => {
									if(res.status == 1){
										Toast.success(res.msg);
										this.step = 2;
									}else{
										Toast.fail(res.msg);
									}
								})
							}else{
								Toast.fail(res.msg);
							}
						});
					}else{
						Toast.fail('手機號格式不正確');
					}
				}
			},
			
			//提交修改密碼
			reset (){
				if(this.pwd.trim() == '' || this.pwd2.trim() == ''){
					Toast.fail('新密碼不能為空');
				}else{
					if(pwdReg(this.pwd)){
						if(this.pwd === this.pwd2){
							resetPwd({
								account: this.account,
								password: this.pwd
							}).then(res => {
								if(res.status == 1){
									Toast.success('密碼重置成功！請登錄');
									this.$router.push('/login');
								}else{
									Toast.fail(res.msg);
								}
							})
						}else{
							Toast.fail('兩次輸入密碼不一致');
						}
					}else{
						Toast.fail('密碼格式為兩位以上字符');
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.forget{
		padding: 1.56rem 0.6rem;
		
		.font-44{
			font-size: 0.44rem;
		}
		.title{
			margin-bottom: 0.1rem;
		}
		.get-code{
			width: 2.48rem;
			height: 0.78rem;
			line-height: 0.78rem;
			margin-top: 0.64rem;
		}
		.tip{
			margin-top: 0.64rem;
			
			.left{
				font-size: 0.24rem;
				color: #A1A7B2;
				
				div{
					font-size: 0.24rem;
					color: #A1A7B2;
				}
			}
		}
		
		.van-password-input{
			width: 3.4rem;
			
			.van-password-input__cursor{
				width: 0.04rem;
				background-color: #6681FA;
			}
			.van-hairline--surround::after{
				border: none;
			}
			.van-hairline--left::after{
				border: none;
			}
			li{
				width: 0.4rem;
				font-size: 0.6rem;
				border-bottom: 0.02rem solid #40444D;
			}
		}
		.reset{
			margin-top: 0.6rem;
		}
	}
	footer{
		left: 0;
		bottom: 0.32rem;
		color: #C6CBD6;
		font-size: 0.2rem;
	}
</style>
